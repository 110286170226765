import React, { useState, useEffect } from 'react'
import { Box, Tabs, Tab, Typography, CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import Chart from "react-apexcharts"
import fetchData from '../../utility/endpointFetch'

import ResearchSourceItemsMetrics from './ResearchSourceItemsMetrics'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ResearchSourcePostTabs = ( {subjectivityChartOptions, subjectivityChartSeries, polarityChartOptions, polarityChartSeries, subjectCoins, sourcePostsStats, postSource, itemSource, hashedWords, attedWords, changeResearchHashTabValue } ) => {
  //console.log("top of ResearchSourceCharts")
  //console.dir(subjectivityChartSeries)
  //console.dir(subjectCoins)

  const [value,setValue] = useState(0)

  //need to get first date on where to start charts
  var firstDate = subjectivityChartSeries[0].data[subjectivityChartSeries[0].data.length - 1].x
  //console.log("firstDate is: " + firstDate)
  //console.log(firstDate)
  const diffDays = (date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
  const dayCount = diffDays(new Date(firstDate), new Date())
  //console.log("dayCount is: " + dayCount)
  //setNumberOfChartDays(dayCount)
  var chartInterval = 'daily'
  if (dayCount >= 3) {
    chartInterval ='hourly'
  } else {
    chartInterval ='15m'
  }
  const chartBaseCurrencySymbol = '$'
  const chartBaseCurrency = 'USD'

  const handleChartChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChartChange} aria-label="chart selection tabs">
          <Tab label="relevance" {...a11yProps(0)} />
          <Tab label="polarity" {...a11yProps(0)} />
          <Tab label="stats" {...a11yProps(0)} />
          {/* subjectCoins.map( (coin,index) => coin.name != '' && coin.name != 'none' ? <Tab label={coin.name.toUpperCase()} {...a11yProps(index+2)} /> : null ) */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} sx={{padding: '5px'}}>
        {Object.keys(subjectivityChartOptions).length === 0 ? null :
          <Chart
            options={subjectivityChartOptions}
            series={subjectivityChartSeries}
            type="scatter"
            height={150}
          />
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
        {Object.keys(polarityChartOptions).length === 0 ? null :
          <Chart
            options={polarityChartOptions}
            series={polarityChartSeries}
            type="scatter"
            height={150}
          />
        }
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ResearchSourceItemsMetrics 
          postSource={postSource} 
          itemSource={itemSource} 
          sourcePostsStats={sourcePostsStats} 
          hashedWords={hashedWords} 
          attedWords={attedWords} 
          changeResearchHashTabValue={changeResearchHashTabValue}
        />
      </TabPanel>
    </Box>
  )
}

export default ResearchSourcePostTabs