import React, { useState, useEffect } from 'react'
import { TableContainer, Table, Toolbar, Box, AppBar, CssBaseline, Container, CircularProgress } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import Navigation from '../Navigation'
import Header from '../Header'
import Footer from '../Footer'
import fetchData from '../../utility/endpointFetch'

import ResearchListItem from './ResearchListItem'

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`
      },
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`,
          padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px',
          width: `calc(100% - 20px)`,
          padding: '16px',
          marginRight: '10px'
      }
  }),
  ...(open && {
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - 20px)`,
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      }
  })
}));

const Research = ( {userDetails} ) => {
  //console.log("top of research")

  const [researchList, setResearchList] = useState()
  const [coinList, setCoinList] = useState([])
  const [twitterList,setTwitterList] = useState([])
  const [telegramList,setTelegramList] = useState([])

  const [isLoading,setIsLoading] = useState(true)

  const theme = useTheme();

  useEffect(() => {
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }

    ;(async () => {
      try {
        const response_feed = await fetchData('api/v1/feed/feed/', "could not receive feed", fetchParams).promise
        console.log("response.json in Research feed list is:")
        console.dir(response_feed)
        setResearchList(response_feed.results)
        try {
          const response_twitter = await fetchData('api/v1/feed/twitter/', "could not receive twitter feed", fetchParams).promise
          console.log("response in Research feed twitter is:")
          console.dir(response_twitter)
          setTwitterList(response_twitter.results)
          try {
            const response_telegram = await fetchData('api/v1/feed/telegram/', "could not receive telegram feed", fetchParams).promise
            console.log("response in Research feed telegram is:")
            console.dir(response_telegram)
            setTelegramList(response_telegram.results)
            setIsLoading(false)
          } catch (error) {
            console.log("error","something is broke in telegram")
          }
        } catch (error) {
          console.log("error","something is broke in twitter")
        }
      } catch (error) {
        console.log("error","something is broke in feed")
      }
    })()
  }, [])

  useEffect(() => {
    const fetchParams = {
      method: 'GET',
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json'
      },
    }
    ;(async () => {
      try {
        const response = await fetchData('api/v1/coin/coinmarket/', "could not receive coinmarket data", fetchParams).promise
        //console.log("response getting coinmarket data")
        //console.dir(response)
        setCoinList(response.results)
      } catch (error) {
        console.log("something is broke getting coinmarket in Research: ", error )
      }
    })()
  }, [])

  if (isLoading) {
    return <CircularProgress />
  } else {
    return (

      <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: 'none'
                }}
            >
                <Toolbar>
                    <Header  />
                </Toolbar>
            </AppBar>

            {/* main content */}
            <Main theme={theme} >
              <Container maxWidth="xl">
                <Navigation selectedPage='research' userDetails={userDetails}/>
                <TableContainer>
                  <Table size="small" sx={{width: '100%', paddingTop: '20px', paddingBottom: '20px'}}>
                    {researchList.map( itemDetail => <ResearchListItem coinList={coinList} feedItemDetails={itemDetail} telegramList={telegramList} twitterList={twitterList} /> )}
                  </Table>
                </TableContainer>
                <Footer />
              </Container>
            </Main>
        </Box>
    )
  }

}

export default Research