import React, { useState, useEffect } from 'react'
import { Avatar, Button, Typography, TextField, Link, Grid, Container, Divider, Stack, IconButton, Switch } from '@mui/material'
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { useTheme } from '@mui/material/styles'

import hofs from '../../utility/hofs'
import auth from '../Auth/auth'
import fetchData from '../../utility/endpointFetch'
import LoginErrorMessageDisplay from './LoginErrorMessageDisplay'
import DisplayCaptcha from './DisplayCaptcha'
import DisplayOTPassword from './DisplayOTPassword'
import LoginTOTP from './LoginTOTP'
import LoginGoogle from './LoginGoogle'
import Logo from '../Header/Logo'
import TwitterIcon from '@mui/icons-material/Twitter';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useCookies } from 'react-cookie';


const Login = ({ setLoggedIn }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['uname', 'pword']);
  //console.log("top of Login")
  //console.dir(setLoggedIn)
  const theme = useTheme();
  const navigate = useNavigate()
  //const { oauth_verifier, oauth_token } = useSearchParams();
  //console.log("oauth_verifier is: ")
  //console.dir(oauth_verifier)
  //console.log("oauth_token is: ")
  //console.dir(oauth_token)

  //fix suggested by github
  LoadingOverlay.propTypes = undefined
  const [rememberMe, setRememberMe] = React.useState(true);
  const toggleRememberMe = (event) => {
    localStorage.setItem('remembered', String(event.target.checked));
    if(!event.target.checked){
      removeCookie('uname')
      removeCookie('pword')
    }
    setRememberMe(event.target.checked);
  };
  const [loginError, setLoginError] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [secondaryErrorMessage, setSecondaryErrorMessage] = useState('')
  const [email, setEmail] = useState()
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false)
  const [checkingLogin,setCheckingLogin] = useState(false)
  const [forceCaptcha, setForceCaptcha] = useState(0)
  const [captchaError, setCaptchaError] = useState(false)
  const [captchaValue, setCaptchaValue] = useState('')
  const [forceOTPUse, setForceOTPUse] = useState(false)
  const [OTPassword, setOTPassword] = useState('')
  const [OTPError, setOTPError] = useState(false)
  const [forceTOTPUse, setForceTOTPUse] = useState(false)
  const [TOTPassword, setTOTPassword] = useState('')
  const [TOTPError, setTOTPError] = useState(false)
  const [twitterLoginToken,setTwitterLoginToken] = useState('')
  const [searchParams,setSearchParams]= useSearchParams()

  useEffect(() => {
    if(document.cookie.includes('uname')){
      if(cookies.uname){
        setUsername(cookies.uname)
      }
      if(document.cookie.includes('pword') && cookies.pword){
        setPassword(cookies.pword)
      }
      setRememberMe(true)
    }
  }, [])

  //console.log("searchParams is: " )
  //console.dir(searchParams)
  //console.log("searchParams.get(oauth_verifier) is: ")
  //console.dir(searchParams.get('oauth_verifier'))
  //console.log("searchParams.get('oauth_token') is: ")
  //console.dir(searchParams.get('oauth_token'))


  const handleUsernameChange = e => {
    setLoginError(false)
    setSecondaryErrorMessage('')
    setUsernameError(false)
    setUsername(e.target.value)
  }

  const handlePasswordChange = e => {
    setLoginError(false)
    setSecondaryErrorMessage('')
    setPasswordError(false)
    setPassword(e.target.value)
  }

  const handleCaptchaChange = e => {
    setCaptchaError(false)
    //setCaptchaValue(e.target.value) //not supported yet...leave at ''
  }

  const handleOTPasswordChange = e => {
    setOTPError(false)
    //setOTPassword(e.target.value) //not supported yet...leave at false
  }

  const handleTOTPChange = e => {
    setTOTPError(false)
    //setTOTPassword(e.target.value) //not supported yet...leave at false
  }

  const refreshCaptcha = e => {
    /*
    setCheckingLogin(true)

    ;(async () => {
      const response = await fetchData('api/v1/login/captcha/refresh', "could not refresh captcha image").promise
      if (response.newCaptchaKey) {
        // a new captcha image was created
        //setForceCaptcha(response.captchaKey)
        //setCaptchaValue('') //not supported yet...leave at false
      } else {
        // hmmm....nothing...as no new captcha could be created...use the current one
      }
      setCheckingLogin(false)
    })()
    */
    return
  }

  useEffect(() => {
    //console.log("top of useEffect in Login")
    if ((searchParams.get('oauth_verifier') !== undefined && searchParams.get('oauth_verifier') !== null) 
      && (searchParams.get('oauth_token') !== undefined && searchParams.get('oauth_token') !== null)) {
      //console.log("oauth_verifier and oauth_token are supplied: ")
      //console.dir(searchParams.get('oauth_verifier'))
      //console.dir(searchParams.get('oauth_token'))
      ;(async () => {
        try {
          const fetchTwitterPostParams = {
            method: 'POST',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              oauth_verifier: searchParams.get('oauth_verifier'),
              oauth_token: searchParams.get('oauth_token'),
              source: 1,
            })                  
          }
  
          const twitterLoginPost = await fetchData(`api/v1/twitter_auth/`, "twitterLoginPost posting twitter login tokens in Login", fetchTwitterPostParams).promise
          //console.log("twitterLoginPost posting twitter login tokens in Login")
          //console.dir(twitterLoginPost)
          //console.dir(fetchTwitterPostParams)

          if (twitterLoginPost.token) {
            if (auth.login(twitterLoginPost.token, twitterLoginPost.expiry)) {
              //token/expiry set in local storage
              setLoggedIn(true)
              navigate('/home')
            } else {
              //could not save token/expiry
              setLoginError(true)
              //setCaptchaValue('') //not supported yet...leave at false
              //setOTPassword('') //not supported yet...leave at false
              //setTOTPassword('') //not supported yet...leave at false
              setLoginErrorMessage(twitterLoginPost.error)
            }
          } else if (twitterLoginPost.error) {
            //console.log("in useEffect where twitterLoginPost.error exists and is: ")
            //console.dir(twitterLoginPost.error)
            setLoginError(true)
            setUsernameError(true)
            setPasswordError(true)
            setLoginErrorMessage(twitterLoginPost.error)
  
          } else {
            //got here because of issues
            setLoginError(true)
            setUsernameError(true)
            setPasswordError(true)
            setLoginErrorMessage('There is an issue with your login credentials.  Please check your login credentials and resubmit.')
  
          }
        } catch (error) {
          console.log("twitterLoginPost posting twitter login tokens in Login: " + error)
        }
      })()   
    } else {
      ;(async () => {
        try {
          const fetchParams = {
            method: 'GET',
            dataType: 'json',
            headers: {
              'Content-Type': 'application/json'
            },
          }

            try {
              const twitterLoginObject = await fetchData(`api/v1/twitter_auth/`, "something is broke getting twitter oauth token in login", fetchParams).promise
              //console.log("twitterLoginObject getting twitter login token in Login")
              //console.dir(twitterLoginObject)

              setTwitterLoginToken(twitterLoginObject[0])

            } catch (error) {
              console.log("error","something is broke getting twitter oauth token in login: " + error)
            }

        } catch (error) {
          console.log("something is broke getting twitter auth: " + error)
        }
      })()    
    }          
  }, [])

  const handleTwitterLogin = () => {
    //console.log("top of twitterLogin")
    ;(async () => {
      try {
        window.location.href = 'https://api.twitter.com/oauth/authorize?oauth_token='+twitterLoginToken
                 
      } catch (error) {
        console.log("something is broke getting oauth token: " + error)
      }
    })()      
  }

  const handleLoginSubmit = e => {

    e.preventDefault()

    // first is to check if email and password are filled in
    const isUsernamePopulated = hofs.isPopulated(username)
    setUsernameError(!isUsernamePopulated)

    const isPasswordPopulated = hofs.isPopulated(password)
    setPasswordError(!isPasswordPopulated)

    var isCaptchaPopulated = true
    if (forceCaptcha !== 0) {
      //captcha is required...collect and send
      //console.log("forceCaptcha in check is: " + forceCaptcha)
      isCaptchaPopulated = hofs.isPopulated(captchaValue)
      setCaptchaError(isCaptchaPopulated)
    } else {
      //captcha is not required...so leave is populated to true
    }

    var isOTPasswordPopulated = true
    if (forceOTPUse) {
      isOTPasswordPopulated = hofs.isPopulated(OTPassword)
      setOTPError(isCaptchaPopulated)
    } else {
      //ot pw is NOT in use...so let is pass into post
    }

    var isTOTPasswordPopulated = true
    if (forceTOTPUse) {
      isTOTPasswordPopulated = hofs.isPopulated(TOTPassword)
      setTOTPError(isCaptchaPopulated)
    } else {
      //totp pw is NOT in use...so let is pass into post
    }

    if ([isUsernamePopulated, isPasswordPopulated, isCaptchaPopulated, isOTPasswordPopulated, isTOTPasswordPopulated].every(b => b)) {
      // there are no errors...send the data to backend to complete registration
      //log("about to send to /login endpoint")
      //console.log("TOTPassword is: " + TOTPassword)
      if(rememberMe){
        setCookie('uname', username, { path: '/', maxAge: 31536000 });
        setCookie('pword', password, { path: '/', maxAge: 31536000 });
      }
      setCheckingLogin(true)
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'username': username,
          'password': password,
          'source': 1
          //'captchaValue':captchaValue,
          //'forceCaptcha': forceCaptcha,
          //'otpPW': OTPassword,
          //'totpPW': TOTPassword
        })
      }
      const errorMessage = 'Error creating your account.  Please contact support to do so.'
      ;(async () => {
        const response = await fetchData('api/v1/login/', errorMessage, fetchParams).promise

        //console.log("response from login endpoint")
        //console.dir(response)

        if (response.token) {
          if (auth.login(response.token, response.expiry)) {
            //token/expiry set in local storage
            setLoggedIn(true)
            navigate('/home')
          } else {
            //could not save token/expiry
            setLoginError(true)
            //setCaptchaValue('') //not supported yet...leave at false
            //setOTPassword('') //not supported yet...leave at false
            //setTOTPassword('') //not supported yet...leave at false
            setLoginErrorMessage('We could not log you in.')
          }
        } else if (response.error) {
          setLoginError(true)
          setUsernameError(true)
          setPasswordError(true)
          setLoginErrorMessage('There is an issue with your login credentials.  Please check your login credentials and resubmit.')

        } else {
          //got here because of issues
          setLoginError(true)
          setUsernameError(true)
          setPasswordError(true)
          setLoginErrorMessage('There is an issue with your login credentials.  Please check your login credentials and resubmit.')

        }
        setCheckingLogin(false)
      })()
    } else {
      //console.log('there were errors in the login submission')
    }
  }

  if (auth.checkLogStatus()) {
    //console.log("auth.checkLogStatus is true")
    return <Navigate to='/home' /> //<Navigate to={hofs.getRole() === 'admin' ? '/admin/' : '/'} />
  }

  const googleSetLoginErrorAndMessage = (error) => {
    //console.log("top of googleSetLoginErrorMessage and error is: ")
    //console.dir(error)
    setLoginError(true)
    let errorObjectProperties = Object.getOwnPropertyNames(error)
    let googleErrorMessage = errorObjectProperties[0] + ": " + error[errorObjectProperties[0]]
    setLoginErrorMessage('There was a Google Login Error with the following error message:'  + googleErrorMessage)
  }

  return (
    <Container component='main' maxWidth='xs' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
      <Logo width={50} />
        <Typography variant='h3' sx={{marginTop: '15px'}}>
          Sign in
        </Typography>
        {loginError &&
          <LoginErrorMessageDisplay errorMessage={loginErrorMessage} secondaryErrorMessage={secondaryErrorMessage} />
        }
        <LoadingOverlay active={checkingLogin} spinner text='Checking Login Details...' fadeSpeed={200}>
        <form sx={{ width: '100%', marginTop: theme.spacing(1) }} noValidate onSubmit={handleLoginSubmit}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='username'
            label='User Name'
            name='username'
            autoComplete='user name'
            autoFocus
            error={usernameError}
            onChange={handleUsernameChange}
            inputProps={{ maxLength: 100 }}
            variant="filled"
            focused
            value={username}
          />
          <Stack direction={'row'} alignItems={'flex-end'} justifyContent={'center'} sx={{justifyContent: 'center', alignItems: 'flex-end', mb: 1}}>
            <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                value={password}
                type={showPassword ? "text" : 'password'}
                id='password'
                autoComplete='current-password'
                error={passwordError}
                onChange={handlePasswordChange}
                inputProps={{ maxLength: 100 }}
                variant="filled"
                focused
            />
            <IconButton  sx={{width: 40, height: 40}} onClick={() => setShowPassword(!showPassword)}>
              {
                showPassword ?
                  <VisibilityIcon color={"primary"} />
                  :
                  <VisibilityOffIcon color={"primary"} />
              }

            </IconButton>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography sx={{fontSize: 16}}>Remember me?</Typography>
            <Switch
                checked={rememberMe}
                onChange={toggleRememberMe}
                inputProps={{ 'aria-label': 'controlled' }}
            />
          </Stack>
          {/* figure out other things before implementing this feature
          <FormControlLabel
            control={<Checkbox value='remember' color='primary' />}
            label='Remember me'
          />
          */}
          <DisplayCaptcha
            captchaKey={forceCaptcha}
            handleCaptchaChange={handleCaptchaChange}
            captchaError={captchaError}
            captchaValue={captchaValue}
            refreshCaptcha={refreshCaptcha} />
          <DisplayOTPassword
            handleOTPasswordChange={handleOTPasswordChange}
            OTPassword={OTPassword}
            OTPError={OTPError}
            forceOTPUse={forceOTPUse}
          />
          <LoginTOTP
            handleTOTPChange={handleTOTPChange}
            TOTPError={TOTPError}
            forceTOTPUse={forceTOTPUse}
            TOTPassword={TOTPassword}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ margin: theme.spacing(3, 0, 2) }}
          >
            Sign In
          </Button>
          <Divider sx={{color: theme.palette.primary.main, "&::before, &::after": {borderColor:theme.palette.primary.main} , width: '100%'}}>or</Divider>
          <LoginGoogle googleSetLoginErrorAndMessage={googleSetLoginErrorAndMessage} />
          <Divider sx={{color: theme.palette.primary.main, "&::before, &::after": {borderColor:theme.palette.primary.main} , width: '100%'}}>or</Divider>
          <Button
                fullWidth
                variant='contained'
                color='primary'
                startIcon={<TwitterIcon />}
                sx={{ margin: theme.spacing(3, 0, 2) }}
                onClick={() => handleTwitterLogin()}
            >
                Twitter Sign In
            </Button>          
          <Grid container>
            <Grid item xs>
              <Link href='/login/reset' variant='body2'>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href='/register' variant='body2'>
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
        </LoadingOverlay>
      </div>
    </Container>
  )
}

export default Login
