import React, { useState, useEffect } from 'react'
import { Toolbar, Box, AppBar, CssBaseline, Container, CircularProgress, Grid, Stack } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import Navigation from '../Navigation'
import Header from '../Header'
import ResearchSourceTabs from './ResearchSourceTabs'
import ResearchSourcePost from './ResearchSourcePost'
import ResearchTabs from './ResearchTabs'
import ResearchChartTabs from './ResearchChartTabs'
import ResearchMiscTabs from './ResearchMiscTabs'
import ResearchChartMiddle from './ResearchChartMiddle'

import fetchData from '../../utility/endpointFetch'

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`
      },
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: `calc(100% - 20px)`,
          padding: '16px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px',
          width: `calc(100% - 20px)`,
          padding: '16px',
          marginRight: '10px'
      }
  }),
  ...(open && {
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - 20px)`,
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      }
  })
}));

const Research2 = ( { userDetails }) => {
  //console.log("top of research2")
  //console.dir(userDetails)

  const [itemSource, setItemSource] = useState('')
  const [postSource,setPostSource] = useState(0)
  const [discordChannel, setDiscordChannel] = useState('')
  const [sourcePostsStats, setSourcePostsStats] = useState({})
  const [searchCriteria,setSearchCriteria] = useState({})
  const [researchHashValue,setResearchHashValue] = useState('')
  const [researchMentionValue,setResearchMentionValue] = useState('')

  const [sourcePostsForChart,setSourcePostsForChart] = useState([])

  const [researchCommentDisplay,setResearchCommentDisplay] = useState({}) 

  const [typeFilter,setTypeFilter] = useState('both')
  const [displayCoinInChart,setDisplayCoinInChart] = useState('bitcoin')
  const [coinSource,setCoinSource] = useState('')

  const [researchMiscTabValue,setResearchMiscTabValue] = useState('1')
  
  const changeResearchMiscTabValue = (event, newValue) => {
    setResearchMiscTabValue(newValue);
  };

  const theme = useTheme();

  const sourceChosen = (source) => {
    //console.log("top of sourceChosen: " + source)
    setItemSource(source)
    setPostSource(0)
    setSearchCriteria({})
  }

  const postSourceChosen = (postSourceSelected, discordChannelPassed='') => {
    //console.log("setting postSource to: " + postSourceSelected + " discordChannelPassed: " + discordChannelPassed)
    setPostSource(postSourceSelected)
    setDiscordChannel(discordChannelPassed)
    setSearchCriteria({})
  }

  const filterByThisType = (filterThis) => {
    //console.log("top of filterByThisType: " + filterThis)
    setTypeFilter(filterThis)
  }

  const settingSourcePostsStats = (statsObject) => {
    setSourcePostsStats(statsObject)
  }

  const searchPostSubmitted = (searchBy) => {
    //console.log("###############setting searchCriteria to:")
    //console.dir(searchBy)
    setPostSource(0)
    setItemSource(searchBy.source)
    setSearchCriteria(searchBy)
  }

  const changeResearchHashValue = ( hashValue ) => {
    setResearchHashValue(hashValue)
    setResearchMiscTabValue("2")
  }

  const changeResearchMentionValue = ( mentionValue) => {
    setResearchMentionValue(mentionValue)
    setResearchMiscTabValue("3")
  }

  const changeResearchCommentDisplay = (researchFilterObject) => {
    //console.log("top of changeResearchCommentDisplay: ")
    //console.dir(researchFilterObject)
    //this will set and pass values on how to display research comments
    //by calendar date
    //by tradepost or not
    //by general or post specific
    //by coin
    setResearchCommentDisplay(researchFilterObject)
  }

  const changeCoinSourceForPosts = (coinSourceChosen) => {
    setPostSource(0)
    setSearchCriteria({})
    setCoinSource(coinSourceChosen)
    setDisplayCoinInChart(coinSourceChosen)
  }

  const settingPostsForChart = (sourcePosts) => {
    setSourcePostsForChart(sourcePosts)
  }

  const searchSourceByThis = () => {
    //nothing....not sure will implement
  }

  const changeCoinInChart = ( coin ) => {
    //console.log("top of changeCoinInChart of Research: " + coin)
    setDisplayCoinInChart(coin)
  }

  if (userDetails.is_researcher === true) {
    return (

        <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              {/* header */}
              <AppBar
                  enableColorOnDark
                  position="fixed"
                  color="inherit"
                  elevation={0}
                  sx={{
                      bgcolor: theme.palette.background.default,
                      transition: 'none'
                  }}
              >
                  <Toolbar>
                      <Header userDetails={userDetails} />
                  </Toolbar>
              </AppBar>

              {/* main content */}
              <Main theme={theme} >
                <Container maxWidth="false">
                  <Navigation userDetails={userDetails} selectedPage="researcher" />
                  <Grid container spacing={.5} sx={{marginTop: '10px'}}>
                    <Grid item xs={2.5} >
                      <Box sx={{backgroundColor: theme.palette.grey[200], height: window.innerHeight, display: 'flex', alignItems: 'flex-start', overflow: 'auto', }} >
                        <ResearchSourceTabs
                          sourceChosen={sourceChosen}
                          itemSource={itemSource}
                          filterByThisType={filterByThisType}
                          searchPostSubmitted={searchPostSubmitted}
                          postSourceChosen={postSourceChosen}
                          discordChannel={discordChannel}
                          typeFilter={typeFilter}
                          changeCoinSourceForPosts={changeCoinSourceForPosts}
                          searchSourceByThis={searchSourceByThis}
                          changeResearchCommentDisplay={changeResearchCommentDisplay}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={5} >
                      <Box sx={{backgroundColor: theme.palette.grey[200], height: '330px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', }} >
                        {/*<ResearchChartTabs coin={displayCoinInChart} />*/}
                        <ResearchChartMiddle coin={displayCoinInChart} sourcePostsForChart={sourcePostsForChart} />

                      </Box>
                      <Box sx={{backgroundColor: theme.palette.grey[200], height: '720px', display: 'flex', alignItems: 'flex-start', overflow: 'auto', }} >
                        <ResearchSourcePost
                          itemSource={itemSource}
                          postSource={postSource}
                          settingSourcePostsStats={settingSourcePostsStats}
                          discordChannel={discordChannel}
                          sourcePostsStats={sourcePostsStats}
                          searchCriteria={searchCriteria}
                          coinSource={coinSource}
                          changeCoinInChart={changeCoinInChart}
                          changeResearchHashValue={changeResearchHashValue}
                          changeResearchMentionValue={changeResearchMentionValue}
                          researchCommentDisplay={researchCommentDisplay}
                          settingPostsForChart={settingPostsForChart}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4.5} >
                      <Box sx={{backgroundColor: theme.palette.grey[200], height: window.innerHeight, display: 'flex', alignItems: 'flex-start',}} >
                        <ResearchMiscTabs 
                          researchHashValue={researchHashValue}
                          researchMiscTabValue={researchMiscTabValue} 
                          researchMentionValue={researchMentionValue}
                          changeResearchMiscTabValue={changeResearchMiscTabValue} />
                        {/*<ResearchTabs coinsList={coinsList} searchPostSubmitted={searchPostSubmitted}/>*/}
                      </Box>
                    </Grid>
                  </Grid>

                </Container>
              </Main>
        </Box>
      )
  } else {
    return null
  }
}

export default Research2