import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Divider, Grid, IconButton, Modal, Stack, Tooltip, Typography } from '@mui/material'

import CurrencyListFilter from '../CurrencyListFilter'
import CurrencyListCategories from '../CurrencyListCategories'
import CurrencyListTimeChoice from '../CurrencyListTimeChoice'
import FavoriteChoicesPopover from './FavoriteChoicesPopover'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const MyCurrenciesFilterRow = ( {screenerCoinList, userObject, changeInMyFavoriteCoins, changeChartTimeFrame, currentTimeFrame, selectedScreener, changeScreener, visibleColumns, setColumns} ) => {
  {/*
    currency name
    currency symbol
    price
    change (1h, 1d, 7d, 30)
    1d volume
    mkt cap
    small chart
    <img class="" width="135" height="50" alt="bitcoin (BTC) 7d chart" data-src="https://www.coingecko.com/coins/1/sparkline" data-srcset="https://www.coingecko.com/coins/1/sparkline 1x" src="https://www.coingecko.com/coins/1/sparkline" srcset="https://www.coingecko.com/coins/1/sparkline 1x">

  useEffect(() => {

  }, [])
  */}

  console.log("top of MyCurrenciesFilterRow")
  console.log("selectedScreener is: " + selectedScreener)
  const [open, setOpen] = React.useState(false);
  const [numActiveColumns, setNumActiveColumns] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const MAX_COLUMNS = 9

  useEffect(() => {
    let active = 0
    for(let [k, v] of Object.entries(visibleColumns)){
      if(v) active++;
    }
    setNumActiveColumns(active)
  }, [visibleColumns])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    maxHeight: 600,
  };
  const handleChecked = (column, event) => {
    const curr = {...visibleColumns}
    if(numActiveColumns >= MAX_COLUMNS && event.target.checked){
      return
    }
    curr[column] = event.target.checked
    setColumns(curr);
  };

  return (
    <Grid container
      sx={{padding: '1px', marginTop: '5px'}}
    >
      <Grid item xs={2}>
        <FavoriteChoicesPopover 
          userObject={userObject} 
          changeInMyFavoriteCoins={changeInMyFavoriteCoins} 
          selectedScreener={selectedScreener}
          screenerCoinList={screenerCoinList}
          changeScreener={changeScreener}
        />
      </Grid>
      <Grid item xs={2}>
        {/*<CurrencyListFilter />*/}
      </Grid>
      <Grid item xs={4}>
        <Typography></Typography>
      </Grid>
      <Grid item xs={3}>
        <CurrencyListTimeChoice changeChartTimeFrame={changeChartTimeFrame} currentTimeFrame={currentTimeFrame} />
      </Grid>
      <Grid item xs={1} sx={{maxWidth: 60}}>
        <Tooltip title="Add/Remove Columns" >
          <Button onClick={() => handleOpen()}>
            Customize
          </Button>
        </Tooltip>
      </Grid>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Stack direction={'column'}>
          <Stack direction={'column'} sx={style}>
            <Stack spacing={1} direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <Typography sx={{fontSize: 16}}>Columns Visible:  </Typography>
              <Stack direction={'row'} spacing={1}>
                <Typography sx={{color: '#1DA1F2', fontSize: 18}}>(</Typography>
                <Typography sx={{color: numActiveColumns >= MAX_COLUMNS ? '#1DA1F2' : '#fff', fontSize: 18}}>{numActiveColumns}</Typography>
                <Typography sx={{color: '#1DA1F2', fontSize: 18}}>/</Typography>
                <Typography sx={{color: '#1DA1F2', fontSize: 18}}>{MAX_COLUMNS}</Typography>
                <Typography sx={{color: '#1DA1F2', fontSize: 18}}>)</Typography>
              </Stack>
            </Stack>
            <Divider />
            {
              Object.entries(visibleColumns).map(([k,v], index) => {
                return <Stack direction={'row'} alignItems={'center'}>
                  <Checkbox onChange={(e) => handleChecked(k, e)} checked={v} />
                  <Typography>{k}</Typography>
                </Stack>
              })
            }
          </Stack>
        </Stack>
      </Modal>
    </Grid>
  )
}

export default MyCurrenciesFilterRow