import React, { useState } from 'react'
import { Avatar, Button, Typography, TextField, Grid, Container, Modal, Fade, Backdrop, Box, } from '@mui/material'
import LoadingOverlay from 'fork-victorvhn-react-loading-overlay'
import { useTheme } from '@mui/material/styles'
import { ThumbUpAlt } from '@mui/icons-material'

import Logo from '../Header/Logo'

import hofs from '../../utility/hofs'
import fetchData from '../../utility/endpointFetch'

const RegisterResearcher = () => {
  const theme = useTheme();

  //fix suggested by github
  LoadingOverlay.propTypes = undefined

  const [researcherRegisterError, setResearcherRegisterError] = useState(false)
  const [researcherRegisterErrorMessage, setResearcherRegisterErrorMessage] = useState('')
  const [researcherRegistrationCode, setResearcherRegistrationCode] = useState()
  const [checkingResearcherCode, setCheckingResearcherCode ] = useState(false)
  const [modalMessage,setModalMessage] = useState('')
  const [modalOpen,setModalOpen] = useState(false)


  const handleResearcherRegistrationCodeChange = e => {
    console.log("e.target.value is: " + e.target.value)
    setResearcherRegisterError(false)
    setResearcherRegisterErrorMessage('')
    setResearcherRegistrationCode(e.target.value)
  }

  const handleResearcherRegistrationCodeSubmit = e => {

    e.preventDefault()

    // first is to check if email and password are filled in
    const isCodePopulated = hofs.isPopulated(researcherRegistrationCode)
    setResearcherRegisterError(!isCodePopulated)

    if ([isCodePopulated].every(b => b)) {
      // there are no errors...send the data to backend to complete registration
      //log("about to send to /login endpoint")
      //console.log("TOTPassword is: " + TOTPassword)
      setCheckingResearcherCode(true)
      const fetchParams = {
        method: 'POST',
        dataType: 'json',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          'key': researcherRegistrationCode,
        })
      }
      const errorMessage = 'Error checking your research code.  Please resubmit and contact support if issues persist.'
      ;(async () => {
        const response = await fetchData('api/v1/users/registerresearcher/', errorMessage, fetchParams).promise

        console.log("response from registerresearcher endpoint")
        console.dir(response)

        if (response) {
          setModalMessage("Your code was accepted.  The role on your account should now allow you to access additional researcher views within the application")
          setModalOpen(true)
          setCheckingResearcherCode(false)
        } else {
          //got here because of issues
          setResearcherRegisterError(true)
          setResearcherRegisterErrorMessage('There is an issue with your researcher registration code.  Please check your code and resubmit.')
          setCheckingResearcherCode(false)
        }

      })()
    } else {
      setResearcherRegisterError(true)
      setResearcherRegisterErrorMessage('Please insert your researcher registration code and submit.')
      setCheckingResearcherCode(false)
    }
  }

  return (
    <Container component='main' maxWidth='xs' sx={{marginTop: '50px', justifyContent: 'center'}}>
      <div sx={{ marginTop: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Logo width={50} />
        <Typography component='h1' variant='h5'>
          Insert The Researcher Registration Code Supplied To You
        </Typography>
        <LoadingOverlay active={checkingResearcherCode} spinner text='Checking Researcher Registration Code...' fadeSpeed={200}>
        <form sx={{ width: '100%', marginTop: theme.spacing(1) }} noValidate onSubmit={handleResearcherRegistrationCodeSubmit}>
          <TextField
            variant="filled"
            focused
            margin='normal'
            required
            fullWidth
            id='researcherRegistrationCode'
            label='Researcher Registration Code'
            name='researcherRegistrationCode'
            autoComplete='researcher registration code'
            autoFocus
            error={researcherRegisterError}
            helperText={researcherRegisterError ? researcherRegisterErrorMessage : null}
            onChange={handleResearcherRegistrationCodeChange}
            inputProps={{ maxLength: 100 }}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={{ margin: theme.spacing(3, 0, 2) }}
          >
            Check Researcher Registration Code
          </Button>
        </form>
        </LoadingOverlay>
        <Modal
          aria-labelledby='spring-modal-title'
          aria-describedby='spring-modal-description'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}
          open={modalOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
          disableEscapeKeyDown={true}
        >
          <Fade in={modalOpen}>
            <Box sx={{ position: 'absolute', width: 600, backgroundColor: theme.palette.dark[800],  border: '2px solid #000000', boxShadow: (theme) => theme.shadows[20], padding: theme.spacing(2, 4, 3), top: '15%', left: 'calc(50vw - 300px)'}}>
              <h2 id='spring-modal-title'>Alpha Centauri Crypto Analysis Researcher Registration </h2>
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: '25px'}}>
                  <Typography variant="subtitle2">{modalMessage}</Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop: '25px'}}>
                  <Button startIcon={<ThumbUpAlt />} variant='contained' href="/home" color='primary'>
                    Click To Return Home
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Fade>
        </Modal>
      </div>
    </Container>
  )
}

export default RegisterResearcher
